
import { Component, Vue } from 'vue-property-decorator'
import { DetailInfo, MemberItem, UserItem } from '@/types/scenarios'
import { ElForm } from 'element-ui/types/form'
import { initWangEdit } from '@/utils/wangEdit'

@Component
export default class ScenariosAdd extends Vue {
  private formData: DetailInfo = {
    scenariosId: '',
    /** 预案名称 */
    scenariosName: '',
    /** 事件类型id */
    typeId: '',
    /** 预案目的 */
    scenariosPurpose: '',
    /** 职责分工 */
    responsibilities: '',
    /** 注意事项 */
    attention: '',
    /** 预案演练 */
    drill: '',
    /** 预案内容 */
    scenariosContent: '',
    /** 小组人员列表 */
    memberList: []
  }

  // 富文本
  private scenariosContent: any = null
  // 事件类型
  private typeList = []
  // 人员列表
  private userList: UserItem[] = []
  private isEdit: number | null = null
  private submitShow = false
  private rulesForm = {
    scenariosName: [
      { required: true, message: '请输入预案名称', trigger: 'change' }
    ],
    typeId: [
      { required: true, message: '请选择事件类型', trigger: 'change' }
    ],
    scenariosPurpose: [
      { required: true, message: '请输入预案目的', trigger: 'change' }
    ],
    memberList: [
      { required: true, message: '请选择小组成员', trigger: 'change' }
    ],
    scenariosContent: [
      { required: true, message: '请输入预案内容', trigger: 'change' }
    ]
  }

  private tableRules = {
    userId: [
      { required: true, message: '请选择姓名', trigger: 'change' }
    ]
  }

  get scenariosId () {
    return this.$route.params.scenariosId || ''
  }

  created () {
    this.getTypeList()
    this.getUserList()
    this.init()
  }

  destroyed () {
    // 销毁编辑器
    this.scenariosContent.destroy()
    this.scenariosContent = null
  }

  // 事件类型
  getTypeList () {
    this.$axios.get(this.$apis.dispatch.selectDispatchTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  // 小组成员列表
  getUserList () {
    this.$axios.get(this.$apis.scenarios.selectUserListByList).then(res => {
      this.userList = res.list || []
    }).finally(() => {
      this.scenariosId && this.getDetail()
    })
  }

  // 富文本初始化
  init () {
    this.$nextTick(() => {
      this.scenariosContent = initWangEdit('#scenariosContent', { placeholder: '请输入预案内容' })
      this.scenariosContent.create()
      // 动态监听富文本改变
      this.scenariosContent.config.onchange = (html: string) => {
        this.formData.scenariosContent = html
        if (html) {
          ;(this.$refs.formData as ElForm).clearValidate()
        }
      }
    })
  }

  //  编辑查详情
  getDetail () {
    this.$axios.get(this.$apis.scenarios.selectDispatchScenariosByScenariosId, {
      scenariosId: this.scenariosId
    }).then(res => {
      this.formData = res || {}
      this.scenariosContent.txt.html(res.scenariosContent || '')
      this.disabledUser()
    })
  }

  // 列表添加
  onAdd () {
    if (this.isEdit !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.formData.memberList.push({
        userId: '',
        name: '',
        mobile: '',
        job: '',
        comments: '',
        isInput: true
      })
      this.isEdit = this.formData.memberList.length - 1
    }
  }

  // 保存当条
  onSave (row: MemberItem, index: number) {
    ;(this.$refs.roleTable as ElForm).validate((valid) => {
      if (valid) {
        row.isInput = false
        this.$set(this.formData.memberList, index, this.formData.memberList[index]) // 重新渲染表格数据
        this.isEdit = null
      }
    })
  }

  // 列表编辑当条
  onEdit (row: MemberItem, index: number) {
    if (this.isEdit !== null) {
      this.$message.warning('请先保存！')
    } else {
      row.isInput = true
      this.isEdit = index
      this.$set(this.formData.memberList, index, this.formData.memberList[index]) // 重新渲染表格数据
    }
  }

  // 删除当条
  onDelete (row: MemberItem, index: number) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.formData.memberList.splice(index, 1)
      this.isEdit = null
      this.userList.forEach(item => {
        if (item.userId === row.userId) {
          item.disabled = false
        }
      })
      this.$message.success('删除成功')
    })
  }

  // 选择人员，带出联系方式
  userChange (row: MemberItem, index: number) {
    const data = this.userList.find(item => item.userId === row.userId)
    if (data) {
      this.formData.memberList[index].mobile = data.mobile
      this.formData.memberList[index].name = data.name
      this.$set(this.formData.memberList, index, this.formData.memberList[index]) // 重新渲染表格数据
      this.disabledUser()
    }
  }

  // 小组成员下拉禁选
  disabledUser () {
    this.userList.forEach(item => {
      item.disabled = false
      this.formData.memberList.forEach(items => {
        if (item.userId === items.userId) {
          item.disabled = true
        }
      })
    })
  }

  // 保存新增、编辑
  onSubmit () {
    (this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        if (this.isEdit !== null) {
          this.$message.warning('小组成员有未保存项！')
        } else {
          this.formData.memberList.forEach(item => {
            delete item.isInput
          })
          this.submitShow = true
          const url = this.scenariosId ? this.$apis.scenarios.updateDispatchScenarios : this.$apis.scenarios.insertDispatchScenarios
          this.$axios.post(url, {
            ...this.formData
          }).then(() => {
            this.$message.success('添加成功')
            this.onCancel()
          }).catch(() => {
            this.formData.memberList.forEach(item => {
              item.isInput = false
            })
          }).finally(() => {
            this.submitShow = false
          })
        }
      }
    })
  }

  // 取消新增、编辑
  onCancel () {
    this.$router.back()
    this.formData = {
      scenariosId: '',
      scenariosName: '',
      typeId: '',
      scenariosPurpose: '',
      responsibilities: '',
      attention: '',
      drill: '',
      scenariosContent: '',
      memberList: []
    }
    ;(this.$refs.formData as ElForm).clearValidate()
  }
}
